import { BuilderContent, BuilderContentVariation } from "@builder.io/sdk";

export const getBuilderVariationAmplitudeData = (
    content: BuilderContent
): { builderVariation: string } => {
    if (Object.keys(content?.variations).length === 0) {
        return {
            builderVariation: null,
        };
    }

    const variationData: BuilderContentVariation = content?.variations?.[content?.testVariationId];
    const variationName = variationData?.name || "default";

    return {
        builderVariation: variationName,
    };
};
