import { BuilderComponent, BuilderContent } from "@builder.io/react";
import { BuilderContent as BuilderContentType } from "@builder.io/sdk";
import { useRouter } from "next/router";
import React, { useCallback } from "react";

import { STALE_IF_ERROR_PAGE_PATH } from "@/shared/constants";

import { parallel } from "@/utils/promises";
import { applyStaleIfErrorCacheHeader } from "@/utils/requests";
import serverSideTranslations from "@/utils/serverSideTranslations";

import { locales } from "@/translations/config";

import { setBuilderVariationProperty } from "@/analytics/tracking";

import { init, resolveAsyncProps } from "@/builder";
import { getBuilderVariationAmplitudeData } from "@/builder/helpers";
import { BuilderModel } from "@/builder/types";

import DefaultLayout from "@/layouts/default";

import {
    getBuilderBannerForIndex,
    getBuilderMessages,
    getBuilderNavLinks,
    getBuilderPage,
} from "@/pages/api/builder/builder";
import { getFooterNavigation, getHeaderNavigation } from "@/pages/api/store-directory/navigation";

import { GetServerSideProps, PageProps } from "@/types/page";

type OwnProps = {
    builderTargetUrlPath: string;
    builderContent: BuilderContentType | null;
    builderMessages: Array<BuilderContentType>;
    builderNavLinks: Array<BuilderContentType>;
    builderBanner: BuilderContentType | null;
    isIndexPage: boolean;
};

type Props = OwnProps & PageProps;

type Params = {
    slug: string[];
};

init(BuilderModel.PAGE);
init(BuilderModel.MESSAGE);
init(BuilderModel.BANNER);

export const getServerSideProps: GetServerSideProps<Props, Params> = async (ctx) => {
    const { locale, params, res } = ctx;
    const { slug = [] } = params;
    const url = `/${slug.join("/")}`;
    const isIndexPage = url === "/";

    const builderContent = await getBuilderPage(url, locale);
    if (!builderContent) {
        if (url === "/" && (locales as ReadonlyArray<string>).includes(locale)) {
            throw new Error(`Home page not found for locale=${locale}`);
        } else if (url === STALE_IF_ERROR_PAGE_PATH) {
            // TODO: after test this on staging and prod, remove this code
            throw new Error(`Error test page`);
        }

        return {
            notFound: true,
        };
    }

    const parallelProps = await parallel({
        headerNavigationItems: () => getHeaderNavigation(locale),
        footerNavItems: () => getFooterNavigation(locale),
        builderMessages: () => getBuilderMessages(locale, { url }),
        builderNavLinks: () => getBuilderNavLinks(locale),
        builderBanner: () => (isIndexPage ? getBuilderBannerForIndex(locale) : null),
        _nextI18Next: () => serverSideTranslations(locale),
    });

    await resolveAsyncProps(builderContent, ctx);

    if (getBuilderVariationAmplitudeData(builderContent)?.builderVariation) {
        // If we have a page with A/B testing we want users always to hit the server
        applyStaleIfErrorCacheHeader(res);
    }

    return {
        props: {
            builderTargetUrlPath: url,
            locale,
            builderContent,
            isIndexPage,
            footerLocalNavItems: parallelProps.footerNavItems[0],
            footerRegionalNavItems: parallelProps.footerNavItems[1],
            ...parallelProps,
        },
    };
};

const IndexPage: React.FC<Props> = ({
    builderTargetUrlPath,
    builderContent,
    builderMessages,
    builderBanner,
    builderNavLinks,
    locale,
    headerNavigationItems,
    footerLocalNavItems,
    footerRegionalNavItems,
}) => {
    // Forcing the router helps the page to render which seems to be a workaround:
    // https://github.com/vercel/next.js/discussions/22512#discussioncomment-1779505
    const router = useRouter();

    const logBuilderInfoToAmplitude = useCallback(
        (content) => {
            const builderVariationData = getBuilderVariationAmplitudeData(content);
            setBuilderVariationProperty(
                locale,
                builderTargetUrlPath,
                builderVariationData.builderVariation
            );
        },
        [locale, builderTargetUrlPath]
    );

    return (
        <DefaultLayout
            currentLocale={locale}
            headerNavigationItems={headerNavigationItems}
            footerLocalNavItems={footerLocalNavItems}
            footerRegionalNavItems={footerRegionalNavItems}
            builderMessages={builderMessages}
            title={builderContent.data.title}
            description={builderContent.data.description}
            builderNavLinks={builderNavLinks}
            useOldStyles={builderContent.data.useOldStyles}
            preventIndexing={builderContent.data.preventIndexing}
        >
            <BuilderComponent
                model={BuilderModel.BANNER}
                content={builderBanner}
                key={`BuilderComponent-${BuilderModel.BANNER}-${router.asPath}`}
            />

            <BuilderContent
                model={BuilderModel.PAGE}
                content={builderContent}
                key={`BuilderContent-${BuilderModel.PAGE}-${router.asPath}`}
            >
                {(variant, loading, totalContent) => {
                    if (loading) return null;
                    logBuilderInfoToAmplitude(totalContent);

                    return (
                        <>
                            <BuilderComponent
                                model={BuilderModel.PAGE}
                                content={totalContent}
                                data={variant}
                                key={`BuilderComponent-${BuilderModel.PAGE}-${router.asPath}`}
                            />
                        </>
                    );
                }}
            </BuilderContent>
        </DefaultLayout>
    );
};

export default IndexPage;
